import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faLocationPin } from '@fortawesome/free-solid-svg-icons';
import './SectionsStyles.css';  // Make sure this path is correct

// const Home = () => (
//     <div id="home" className="section">
//         <h1 className="heading">Welcome to Smart Vision</h1>
//         <h1 className="heading">Your Vision. Our Expertise. Exceptional Events.</h1>
//         <img src="/2.webp" alt="Event Management" className="homeImage" />
//         <p className="context">At Smart Vision, we specialize in creating unforgettable experiences through expert event management and top-tier event services. From intimate gatherings to large-scale corporate events, our dedicated team transforms your ideas into seamless, memorable occasions.</p>
//     </div>
// );

const Home = () => (
    <div id="home" className="section">
        <div>
            <h1 className="heading">Welcome to Smart Vision</h1>
        </div>
        <div className="home-content">
            <img src="/2.webp" alt="Event Management" className="homeImage" />
            <p className="context">At Smart Vision, we specialize in creating unforgettable experiences through expert event management and top-tier event services. From intimate gatherings to large-scale corporate events, our dedicated team transforms your ideas into seamless, memorable occasions.
            <br></br><br></br><div className='ContextTitle'>What We Do</div><br></br>

            •⁠ Event Planning & Coordination: Comprehensive planning to ensure everything runs smoothly.<br></br>
            •⁠ Creative Design & Decor: Tailored themes and stunning decor to elevate your event.<br></br>
            •⁠ Venue Selection & Logistics: We find the perfect venue and manage all the logistics.<br></br>
            •⁠ Audio-Visual Solutions: Cutting-edge technology to bring your event to life.<br></br>
            •⁠ Catering & Entertainment: Exceptional food and entertainment options to delight your guests.<br></br>

            <br></br><br></br><div className='ContextTitle'>Why Choose Us?</div><br></br>

            •⁠⁠ Experience: Years of expertise in crafting flawless events.<br></br>
            •⁠ Tailored Solutions: Every event is designed around your vision and goals.<br></br>
            •⁠ Attention to Detail: We leave no stone unturned to ensure your event is a success.<br></br>
            •⁠ End-to-End Service: We handle every aspect, so you can enjoy the moment.<br></br>

            <br></br><br></br><div className='ContextTitle'>Let's Bring Your Vision to Life</div><br></br>

            Whether it’s a corporate gathering, wedding, or special celebration, Smart Vision is here to help you create an extraordinary event. Ready to make your event a reality?<br></br><br></br>
            <a href="#contact" class="ContactUsBtn">Contact Us</a>
            </p>
        </div>
    </div>
);



const About = () => (
    <div id="about" className="section">
        <div>
            <h1 className="heading">Your Vision. Our Expertise. Exceptional Events.</h1>
        </div>
        <div className="home-content">
            <p className="context">
            Welcome to Smart Vision, your trusted partner in delivering unforgettable events. We are a passionate team of event management professionals dedicated to creating seamless, impactful experiences that exceed expectations.<br></br><br></br>

            With years of experience in the industry, we specialize in planning, coordinating, and executing a wide range of events — from corporate conferences and product launches to weddings and private parties. Our expertise ensures that every event is carefully tailored to your unique vision, and our commitment to excellence drives us to deliver high-quality results, every time.<br></br><br></br>

            At Smart Vision, we understand that every event is a reflection of your brand, values, and personal style. That's why we take the time to listen, collaborate, and transform your ideas into reality. Whether you're planning an intimate gathering or a large-scale event, our creative approach and attention to detail guarantee a memorable experience for all your guests.
            </p>
            <img src="/3.webp" alt="Event Management" className="homeImage" />
        </div>
    </div>
);

const Services = () => (
    <div id="services" className="section">
        <div>
            <h1 className="heading">Event Management Services</h1>
        </div>
        <div className="home-content">
            <p className="context">
                <div className='ContextTitle'>1. Event Planning & Consultation</div><br></br>
                - Concept development<br></br>
                - Budget management<br></br>
                - Timeline creation<br></br>
                - Vendor coordination<br></br><br></br>

                <div className='ContextTitle'>2. Venue Selection & Management</div><br></br>
                - Venue sourcing and booking<br></br>
                - Site inspection<br></br>
                - Layout and design planning<br></br><br></br>

                <div className='ContextTitle'>3. Event Coordination & Execution</div><br></br>
                - On-site event management<br></br>
                - Staff  coordination<br></br>
                - Event day timeline management<br></br><br></br>

                <div className='ContextTitle'>4. Logistics & Transportation</div><br></br>
                - Guest transportation<br></br>
                - Equipment rental and logistics<br></br>
                - Travel arrangements for speakers or VIPs<br></br><br></br>

                <div className='ContextTitle'>5. Catering & Hospitality Service's</div><br></br>
                - Custom catering packages (breakfast, lunch, dinner, snacks)<br></br>
                - Beverage services (cocktail hours, coffee stations)<br></br>
                - Dietary requirement accommodations
            </p>

            <p className="context">
                <div className='ContextTitle'>6. Audio-Visual & Technical Support</div><br></br>
                - AV equipment rental (microphones, projectors, lighting)<br></br>
                - Event streaming services<br></br>
                - Technical staff for setup and troubleshooting<br></br><br></br>

                <div className='ContextTitle'>7. Decor & Design Services</div><br></br>
                - Theme-based event styling<br></br>
                - Custom floral arrangements<br></br>
                - Set design and props<br></br><br></br>

                <div className='ContextTitle'>8. Entertainment & Activities</div><br></br>
                - Live performances (music, dance, etc.)<br></br>
                - Interactive experiences (photo booths, games)<br></br>
                - Guest speakers or emcees<br></br><br></br>

                <div className='ContextTitle'>9. Marketing & Promotion</div><br></br>
                - Event branding and marketing strategies<br></br>
                - Social media campaigns<br></br>
                - Email marketing and invitations<br></br><br></br>

                <div className='ContextTitle'>10. Corporate Event Solutions</div><br></br>
                    - Conferences and seminars<br></br>
                    - Product launches<br></br>
                    - Team-building events and retreats<br></br><br></br>

                <div className='ContextTitle'>11. Weddings & Private Events</div><br></br>
                    - Wedding planning and coordination<br></br>
                    - Private parties and celebrations (birthdays, anniversaries)<br></br>
                    - Custom-themed events
            </p>
            
        </div>
        <img src="/sac.png" className="fullwidth-image" alt="Descriptive Text" />
    </div>
);

const Contact = () => (
    <div id="contact" className="contact-section">
        <div className="contact-container">
            <h1 className="heading">Connect with us</h1>
            <div className="contact-details">
                <a className="contact-item" href="tel:0510773204">
                    <FontAwesomeIcon icon={faPhone} /> 0510773204
                </a>
                <a className="contact-item" href="mailto:support@smartvisionarabia.com" target='_blank' rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faEnvelope} className="icon" />
                    support@smartvisionarabia.com
                </a>
                <p className="contact-item">
                    <FontAwesomeIcon icon={faLocationPin} style={{ color: 'white' }} />Sulaymaniyah, Riyadh, Saudi Arabia
                </p>
            </div>
        </div>
    </div>
);

export { Home, About, Services, Contact };
