// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;

import React from 'react';
import HeaderComponent from './components/HeaderComponent/HeaderComponent';
import { Home, About, Services, Contact } from './components/SectionsComponent/SectionsComponent';

const App = () => {
    return (
        <div>
            <HeaderComponent />
            <Home />
            <About />
            <Services />
            <Contact />
        </div>
    );
};

export default App;


