import React, { useState } from 'react';
import { Link } from 'react-scroll';
import './HeaderComponent.css';

const HeaderComponent = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <header className="header">
            <div className="container">
                {/* Logo */}
                <Link to="home" smooth={true} duration={500} className="logoLink">
                    <img src="/SVLogo2.png" alt="Logo" className="logo" />
                </Link>

                {/* Hamburger Button for Mobile */}
                <div className="hamburger" onClick={toggleMenu}>
                    <div className="hamburgerLine"></div>
                    <div className="hamburgerLine"></div>
                    <div className="hamburgerLine"></div>
                </div>

                {/* Side Menu for Mobile */}
                <div
                    className="sideMenu"
                    style={{
                        transform: isMenuOpen ? 'translateX(0)' : 'translateX(100%)',
                    }}
                >
                    <button className="closeButton" onClick={toggleMenu}>
                        &times;
                    </button>
                    <ul className="navList">
                        <li className="navItem">
                            <Link to="home" smooth={true} duration={500} onClick={toggleMenu}>
                                Home
                            </Link>
                        </li>
                        <li className="navItem">
                            <Link to="about" smooth={true} duration={500} onClick={toggleMenu}>
                                About
                            </Link>
                        </li>
                        <li className="navItem">
                            <Link to="services" smooth={true} duration={500} onClick={toggleMenu}>
                                Services
                            </Link>
                        </li>
                        <li className="navItem">
                            <Link to="contact" smooth={true} duration={500} onClick={toggleMenu}>
                                Contact
                            </Link>
                        </li>
                    </ul>
                </div>

                {/* Desktop Navigation */}
                <nav className="desktopNav">
                    <ul className="navList">
                        <li className="navItem">
                            <Link to="home" smooth={true} duration={500}>
                                Home
                            </Link>
                        </li>
                        <li className="navItem">
                            <Link to="about" smooth={true} duration={500}>
                                About
                            </Link>
                        </li>
                        <li className="navItem">
                            <Link to="services" smooth={true} duration={500}>
                                Services
                            </Link>
                        </li>
                        <li className="navItem">
                            <Link to="contact" smooth={true} duration={500}>
                                Contact
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
    );
};

export default HeaderComponent;
